import $ from 'jquery';

export default {

	init() {

		var container = document.querySelectorAll('.ElasticContent');

		if( container.length > 0 ) {

			function elasticContentScroll( container ) {

				var scrollButton = container.querySelector('.button');

				if( scrollButton ) {

					var scrollButtonHref = scrollButton.getAttribute('href');
					var scrollButtonHrefSplit = scrollButtonHref.split('#');


					if( scrollButtonHrefSplit[0] == '' && document.querySelector('#'+scrollButtonHrefSplit[1]) ) {

						scrollButton.addEventListener('click', function( e ) {

							e.preventDefault();

							$('html ,body').animate({'scrollTop': document.querySelector('#'+scrollButtonHrefSplit[1]).offsetTop + document.querySelector('.Header').offsetHeight }, 'smooth');

						});

					}
					
				}

			}

			container.forEach( function( box,index) {

				elasticContentScroll(box);

			})

		}

	},
	finalize() {

	}

}