import $ from 'jquery';
import slick from 'slick-carousel';

export default {

	init() {

		var container = document.querySelector('.ElasticSlaider');

		if( container ) {

			var percentOfHeight = 0;
			var calcWidth = 0;


			var slickSlide = null;
			var slickSlideLength = 0;
			var image = null;

			function slickShort() {

				slickSlide = container.querySelectorAll('.slick-slide');
				slickSlideLength = slickSlide.length;

				calcImagesWidth( slickSlide , slickSlideLength);

			}

			function calcImagesWidth( slickImages, slickImagesLength ) {

				var image = null;

				slickImages.forEach( function( slickImg, index ) {

					image = slickImg.querySelector('.elasticSlaider__image');

					percentOfHeight = ( container.offsetHeight * 100) / parseFloat( image.getAttribute('data-height') );

					calcWidth = image.getAttribute('data-width') * percentOfHeight / 100;

					image.style.width = calcWidth+'px';

				}); 

			}	

			$(container).slick({
				arrows: false,
				dots: false,
				infinite: true,
				slidesToScroll: 1,
				variableWidth: true,
				speed: 500,
				centerMode: true,
				autoplay: true,
				autoplaySpeed : 2000,
			});

			slickShort();

			container.classList.remove('hide');

			window.addEventListener( 'resize', function() {

				slickShort();

			});

		}

	},
	finalize() {

	}

}