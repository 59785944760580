export default {

	init() {

		var container = document.querySelector('.ContactForm');

		if( container ) {

			class ContactForm {

				constructor( container ) {

					this.container = container;
					this.fields = this.container.querySelectorAll('.formField');
					this.submitButton = this.container.querySelector('.contactFormSubmit');


					this.callbackSuccess = this.container.getAttribute('data-success');
					this.callbackError = this.container.getAttribute('data-error');
					this.callbackEmptyLabel = this.container.getAttribute('data-empty-label');
					this.callbackEmptyEmail = this.container.getAttribute('data-empty-email');

					this.callback = null;
					this.requestData = [];
					this.formSubmitValue = false;

				}

				callbackMessage( message, status = 'error' ) {

					this.callback = this.container.querySelectorAll('.callback');

					if( this.callback.length > 0 ) {

						this.callback.forEach( function(item,index) {
							item.remove();
						})

					}

					if( message ) {

						this.container.querySelector('.contactFormSubmit').insertAdjacentHTML('beforebegin','<p class="callback '+status+'">'+message+'</p>');

					}

				}

				validEmail() {

					var _this = this;
					var value = false;

					this.fields.forEach( function( input,index ) {

						if( input.type=='email' ) {

							if( /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(input.value) ) {

								_this.callbackMessage( '' );
								input.classList.remove('empty');
								value = true;

							} else {

								_this.callbackMessage( _this.callbackEmptyEmail );
								input.classList.add('empty');

							}

						}

					});

					return value;

				}

				validField() {

					this.submitButton.classList.add('disable');

					// required fields
					var fields = this.container.querySelectorAll('.formField--required');

					fields.forEach( function( input,index ) {

						if( input.type == 'checkbox' ) {

							if( input.checked == false ) {

								input.classList.add('empty')

							} else {

								input.classList.remove('empty');

							}

						} else {

							if( input.value ) {

								input.classList.remove('empty');

							} else {

								input.classList.add('empty');

							}

						}

					});

					if( this.container.querySelectorAll('.formField--required.empty').length == 0 ) {

						this.callbackMessage( '' );

						if( this.validEmail() ) {

							this.send();

						} else {

							this.submitButton.classList.remove('disable');

						}

					} else {

						this.submitButton.classList.remove('disable');
						this.callbackMessage( this.callbackEmptyLabel );
						return false;

					}	

				}

				send() {

					var _this = this;

					// -- requestData -- //

						this.requestData = [];
						this.requestData.push( 'action=sendMail' );
						this.requestData.push( 'security='+this.container.querySelector('input#authorizeContactForm').value );

						this.fields.forEach( function(input,index) {

							if( input.type!='checkbox' ) {

								_this.requestData.push( input.getAttribute('name')+'='+input.value );

							}

						});

						this.requestData = this.requestData.join('&');

					// -- END -- //


					var request = new XMLHttpRequest();

					request.onreadystatechange = function() {

						if( request.responseText ) {

							var response = JSON.parse(request.responseText);

							if( response.status == 'success' ) {

								_this.callbackMessage( _this.callbackSuccess, 'success' );
								_this.submitButton.classList.add('turnOff');
								_this.formSubmitValue = true;

							} else if( response.status == 'error' ) {

								_this.callbackMessage( _this.callbackError, 'error');
								_this.submitButton.classList.remove('disable');

							}

						} 

					}

					// Set up our request
					request.open('POST', document.querySelector('footer').getAttribute('data-endpoint') );

					// Add the required HTTP header for form data POST requests
					request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

					// Finally, send our data.
					request.send( this.requestData );

				}

			}

			var object = new ContactForm( container );

			container.querySelector('form').addEventListener('submit', function(e) {

				e.preventDefault();

				if( !object.formSubmitValue && !object.submitButton.classList.contains('disable') ) {

					object.validField();

				}

			});

		}	

	},
	finalize() {

	}

}