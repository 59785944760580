import $ from 'jquery';
import slick from 'slick-carousel';

export default {

	init() {

		var container = document.querySelectorAll('.ElasticSlaiderLogo');

		if( container.length > 0 ) {

			function elasticSlaiderLogoSlick( container ) {

				var carousel = container.querySelector('.elasticSlaiderLogo__carousel');

				$(carousel).slick({
					arrows: false,
					dots: false,
					slidesToShow: 6,
					slidesToScroll: 1,
					rows: 0,
					infinite: true,
					autoplay: 1,
					autoplaySpeed: 3000,
					responsive : [
						{
							breakpoint: 1361,
							settings : {
								slidesToShow: 5,								
							}
						},
						{
							breakpoint: 1025,
							settings: {
								slidesToShow: 4,
							}
						},
						{
							breakpoint: 791,
							settings: {
								slidesToShow: 3,
							}
						},
						{
							breakpoint: 541,
							settings: {
								slidesToShow: 2,
							}
						},
					]
				});

			}

			container.forEach( function( box,index ) {

				elasticSlaiderLogoSlick( box );

			});

		}

	},
	finalize() {

	}

}