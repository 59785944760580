// Defaults
import './style.scss';
import Router from './scripts/Router';
import LazyLoad from './scripts/lazyLoad';

// Layout
import subpage from '../src/Layout/Subpage';

// Pages
import homepage from '../src/Pages/Homepage/Homepage';
import defaulttemplate from '../src/Pages/DefaultTemplate/DefaultTemplate';
import singleblog from '../src/Pages/SingleBlog/SingleBlog';


LazyLoad.init();

const routes = new Router({
	homepage,
	defaulttemplate,
	singleblog
});

subpage.init();

document.addEventListener("DOMContentLoaded", function() {
	routes.loadEvents();
});