import $ from 'jquery';
import slick from 'slick-carousel';

export default {

	init() {

		var container = document.querySelector('.ArticleImageRow');

		if( container ) {

			var slider = container.querySelector('.content__opinions');

			var buttonNext = container.querySelector('.row__button--next');
			var buttonPrev = container.querySelector('.row__button--prev');
			
			$(slider).slick({
				arrows : false,
				infinite: true,
				speed: 500,
				fade: true,
				cssEase: 'linear',
				slidesToShow: 1,
				slidesToShow: 1,
				adaptiveHeight : true
			});


			buttonNext.addEventListener('click', function() {

				$(slider).slick('slickNext');

			});

			buttonPrev.addEventListener('click', function() {

				$(slider).slick('slickPrev');

			});

		}

	}, 
	finalize() {

	}

}