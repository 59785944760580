import ElasticImageContent from '../../Sections/ElasticImageContent/ElasticImageContent';
import ElasticFullImageContent from '../../Sections/ElasticFullImageContent/ElasticFullImageContent';
import ElasticSlaider from '../../Sections/ElasticSlaider/ElasticSlaider';
import ElasticSlaiderLogo from '../../Sections/ElasticSlaiderLogo/ElasticSlaiderLogo';
import Newsletter from '../../Sections/Newsletter/Newsletter';
import ElasticContent from '../../Sections/ElasticContent/ElasticContent';

export default {

	init() {

		ElasticImageContent.init();
		ElasticFullImageContent.init();
		ElasticSlaider.init();
		ElasticSlaiderLogo.init();
		Newsletter.init();
		ElasticContent.init();

	},
	finalize() {

	}

}