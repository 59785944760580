export default {

	init() {	

		var container = document.querySelector('.Languages');

		if( container ) {

			function languagesChange( container ) {

				container.addEventListener( 'click', function(e) {

					e.stopPropagation();

					if( container.classList.contains('open') ) {

						container.classList.remove('open');

					} else {

						container.classList.add('open');

					}

				});

				document.addEventListener('click', function() {

					if( container.classList.contains('open') ) {

						container.classList.remove('open');

					}

				});

				container.querySelector('ul').addEventListener('click', function(e) {

					e.stopPropagation();

				});

			}

			languagesChange( container );

		}

	},
	finalize() {

	}

}