import lozad from 'lozad';

const LazyLoad = new function() {

    this.init = function (node) {

        var pictureObserverAfterLoadDOM;

        function loadImage() {

        	pictureObserverAfterLoadDOM = lozad('.lazy-load-ar', {
                threshold: 0
            })
            pictureObserverAfterLoadDOM.observe();

        }

        if(document.readyState === 'complete') {
        	loadImage();
        }
        else {

            document.addEventListener('readystatechange', function(e) {

                if( e.target.readyState === 'complete' ) {
                    loadImage();
                }

            });

        }

    }

};
export default LazyLoad;