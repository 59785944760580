import $ from 'jquery';
import cookie from '../Components/Cookie/Cookie.js';
import languages from '../Components/Languages/Languages.js';

export default {
	init() {
		
		cookie.init();
		languages.init();

		var container = document.querySelector('.Header');

		if( container ) {

			function headerScrollToSection( container ) {

				var items = container.querySelectorAll('.menu__item');

				if( items ) {

					items.forEach( function(item,index) {

						if( item.getAttribute('href').split('#')[0] == '' && document.querySelector('#'+item.getAttribute('href').split('#')[1]) ) {

							item.addEventListener('click', function(e) {

								e.preventDefault();

								if( window.innerWidth <= 800 ) {

									$('html ,body').animate({'scrollTop': (document.querySelector('#'+item.getAttribute('href').split('#')[1] ).offsetTop + container.offsetHeight ) }, 'smooth');

								} else {

									$('html ,body').animate({'scrollTop': document.querySelector('#'+item.getAttribute('href').split('#')[1] ).offsetTop }, 'smooth');

								}

							});

						}

					});

				}

			}

			function headerMobileMenu( container ) {

				var hamburger = container.querySelector('button.hamburger__button');

				var mobileMenu = container.querySelector('nav.hamburger__menu');
				var mobileMenuItem;

				if( hamburger && mobileMenu ) {

					mobileMenuItem = mobileMenu.querySelectorAll('.menu__item');

					hamburger.addEventListener('click', function(e) {

						e.stopPropagation();

						if( mobileMenu.classList.contains('active') ) {

							mobileMenu.classList.remove('active');

						} else {

							mobileMenu.classList.add('active');

						}

					});

					document.addEventListener('click', function() {

						if( mobileMenu.classList.contains('active') ) {

							mobileMenu.classList.remove('active');

						}

					});

					mobileMenu.addEventListener('click', function(e) {

						e.stopPropagation();

					});

					mobileMenuItem.forEach( function( item,index ) {

						item.addEventListener('click', function() {

							mobileMenu.classList.remove('active');

						});

					});

				}

			};

			headerScrollToSection( container );
			headerMobileMenu( container );

		}

	},
	finalize(){

	}

}