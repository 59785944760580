export default {

	init() {

		var container = document.querySelector('.NewsletterComponent');

		if( container ) {

			class NewsletterComponent {

				constructor( container ) {

					this.container = container;
					this.fields = this.container.querySelectorAll('.formField');
					this.submitButton = this.container.querySelector('.newsletterComponentSubmit');


					this.callbackSuccess = this.container.getAttribute('data-success');
					this.callbackError = this.container.getAttribute('data-error');
					this.callbackEmptyLabel = this.container.getAttribute('data-empty-label');
					this.callbackEmptyEmail = this.container.getAttribute('data-empty-email');

					this.callbackMessage = null;
					this.requestData = [];
					this.formSubmitValue = false;

				}

				callback( message, status = 'error' ) {

					this.callbackMessage = this.container.querySelectorAll('.callBack');

					if( this.callbackMessage.length > 0 )  {

						this.callbackMessage.forEach( function( box, index ){
							box.remove();
						});

					}

					if( message ) {

						this.container.querySelector('.newsletter--form .points').insertAdjacentHTML('afterend', '<p class="callBack '+status+'">'+message+'</p>' );

					}

				}

				validEmail() {

					var _this = this;
					var value = false;

					this.fields.forEach( function( input, index ) {

						if( input.type == 'email' ) {

							if( input.value && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(input.value) ) {

								_this.callback();
								input.classList.remove('empty');
								input.classList.add('currect');
								value = true;

							} else {

								_this.callback( _this.callbackEmptyEmail );
								input.classList.remove('currect');
								input.classList.add('empty');
								value = false;

							}

						}

					});

					return value;

				}

				validCheckbox() {

					this.fields.forEach( function( input, index ) {

						if( input.type == 'checkbox' ) {

							if( input.checked == true ) {

								input.classList.remove('empty');

							} else {

								input.classList.add('empty');

							}

						}

					});

					if( this.container.querySelectorAll('input[type="checkbox"].empty').length == 0 ) {

						return true;

					} else {

						return false;

					}

				}

				validField() {

					this.submitButton.classList.add('disable');

					if( this.validEmail() ) {

						this.callback('');

						if( this.validCheckbox() ){

							return true;

						} else {

							this.callback( this.callbackEmptyLabel );
							this.submitButton.classList.remove('disable');
							return false;

						}

					} else {

						this.callback( this.callbackEmptyEmail );
						this.submitButton.classList.remove('disable');
						return false;

					}

				}

				send() {

					var _this = this;

					// -- Request data -- // 

						this.requestData = [];

						this.requestData.push( 'action=newsLetter' );
						this.requestData.push( 'security='+this.container.querySelector('input#authorizeNewsletterComponent').value );
						this.requestData.push( 'email='+this.container.querySelector('input[name="email"]').value );
						this.requestData.push( 'lang='+container.querySelector('input[name="lang"]').value );

						if( container.querySelector('input[name="name"]').value ) {

							this.requestData.push( 'name='+container.querySelector('input[name="name"]').value );

						}

						this.requestData = this.requestData.join('&');

					// -- END -- // 

					var request = new XMLHttpRequest();

					request.onreadystatechange = function() {

						if( request.responseText ) {

							var response = JSON.parse(request.responseText);

							if( response.status == 'success' ) {
								
								_this.submitButton.classList.add('turnOff');
								_this.callback( _this.callbackSuccess, 'success' );
								_this.formSubmitValue = true;

							} else if( response.status == 'error' ) {

								_this.callback( _this.callbackError, 'error');
								_this.submitButton.classList.remove('disable');

							}

						}

					}

					// Set up our request
					request.open('POST', document.querySelector('footer').getAttribute('data-endpoint') );

					// Add the required HTTP header for form data POST requests
					request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

					// Finally, send our data.
					request.send( this.requestData );

				}

			}

			var buttonChangeLayout = container.querySelector('.newsletterShowForm');
			var newsletterForm = container.querySelector('.newsletter--form');
			var inputEmail = newsletterForm.querySelector('.formField--email');

			if( buttonChangeLayout ) {

				buttonChangeLayout.addEventListener('click', function() {

					container.querySelector('.newsletter--greetings').classList.add('hide');
					container.querySelector('.newsletter--form').classList.remove('hide');

				});
				
			}

			if( window.location.hash ) {

				var hashs = (window.location.hash).split('#');

				if( hashs.includes('about') ) {

					buttonChangeLayout.click();

				}

			}	

			var object = new NewsletterComponent( container );	

			newsletterForm.addEventListener('submit', function(e) {

				e.preventDefault();

				if( !object.formSubmitValue && !object.submitButton.classList.contains('disable') ) {

					if( object.validField() ) {

						object.send();

					}

				}

			});

			inputEmail.addEventListener('input', function() {

				if( inputEmail.value && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inputEmail.value) ) {

					inputEmail.classList.remove('empty');
					inputEmail.classList.add('currect');

				} else {

					inputEmail.classList.remove('currect');
					inputEmail.classList.add('empty');

				}

			});

		}

	},
	finalize() {

	}

}