import $ from 'jquery';
import slick from 'slick-carousel';

export default {

	init() {

		var container = document.querySelector('.ElasticImageContent');

		if( container ) {

			function elasticImageContentSlick( container ) {

				var imagesContainer = container.querySelector('.elasticImageContent__image .images');

				if( imagesContainer ) {

					var images = imagesContainer.querySelectorAll('.image-wrapper');

					if( images.length > 1 ) {

						$(imagesContainer).slick({
							arrows : false,
							dots : true,
							infinite: true,
							speed: 500,
							autoplay: true,
							autoplaySpeed : 2000,
							fade: true,
							cssEase: 'linear',
							slidesToShow: 1,
							appendDots : container.querySelector('.elasticImageContent__image .image__dots'),
						});

					}

				}

			}	

			function elasticImageContentScroll( container ) {

				var scrollButton = container.querySelector('.elasticImageContent__content .button');
				var scrollButtonHref = scrollButton.getAttribute('href');
				var scrollButtonHrefSplit = scrollButtonHref.split('#');

				if( scrollButtonHrefSplit[0] == '' && document.querySelector('#'+scrollButtonHrefSplit[1]) ) {

					scrollButton.addEventListener('click', function( e ) {

						e.preventDefault();

						$('html ,body').animate({'scrollTop': document.querySelector('#'+scrollButtonHrefSplit[1]).offsetTop + document.querySelector('.Header').offsetHeight }, 'smooth');

					});

				}

			}

			elasticImageContentSlick( container );
			elasticImageContentScroll( container )

		}

	},
	finalize() {

	}

}