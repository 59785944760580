export default {

	init() {

		var container = document.querySelector('.Newsletter');

		if( container ) {

			// -- objects -- //

				var form = container.querySelector('form.newsletter__form');
				var submitButton = form.querySelector('.newsletterSubmit');
				var fields = container.querySelectorAll('.formField');

			// -- END -- //



			// -- variable -- // 

				var validation = false;

				var emptyInput;
				var formSubmit = false;
				var requestData = [];
				var request;
				var response;

				var callback;
				var callbackSuccess = container.getAttribute('data-success');
				var callbackError = container.getAttribute('data-error');
				var callbackEmptyLabel = container.getAttribute('data-empty-label');
				var callbackEmptyEmail = container.getAttribute('data-empty-email');

				var attributeName = '';

			// -- END -- //



			// -- functions -- // 

				function setUrlParameter( url ) {

					if( container.classList.contains('newsletter--share__file') ) {

						window.open(url, '_blank');
						container.querySelector('.newsletter--thanks .Button').setAttribute('href', url);

					} else if( container.classList.contains('newsletter--share__url') ) {

						container.querySelector('.newsletter--thanks .newsletter__field').setAttribute('value', url);

					}

				}

				function changeLayout() {

					container.querySelector('.newsletter--form').classList.add('hide');
					container.querySelector('.newsletter--thanks').classList.remove('hide');

				}

				function removeCallBack( message, status = 'error' ) {

					callback = container.querySelectorAll('.callBack');

					if( callback.length > 0 )  {

						callback.forEach( function( box, index ){
							box.remove();
						});

					}

					if( message ) {

						container.querySelector('.form__agreements').insertAdjacentHTML('beforebegin', '<p class="callBack '+status+'">'+message+'</p>' );

					}

				}

				function validateEmail() {

					fields.forEach( function( input, index ) {

						if( input.type == 'email' ) {

							if( input.value && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(input.value) ) {

								input.classList.remove('empty');

								validateCheckbox();

							} else {

								input.classList.add('empty');
								removeCallBack( callbackEmptyEmail,'error','email' );

								validation = false;

							}

						}

					});

				}

				function validateCheckbox() {

					fields.forEach( function( input, index) {

						if( input.type == 'checkbox' ) {

							if( input.checked == false ) {

								input.classList.add('empty');

							} else {

								input.classList.remove('empty');

							}

						}

					});


					if( container.querySelectorAll('.form__agreements li label input[type="checkbox"].empty').length == 0 ) {

						validation = true;

					} else {

						removeCallBack(callbackEmptyLabel,'error','label');
						validation = false;

					}

				}

			// -- END -- //



			form.addEventListener('submit', function(e) {

				e.preventDefault();

				if( !submitButton.classList.contains('disable') && !formSubmit) {

					submitButton.classList.add('disable');
					validateEmail();

					if( validation ) {

						// -- Request data -- // 

							requestData = [];

							requestData.push( 'action=newsLetter' );
							requestData.push( 'security='+container.querySelector('input#authorizeNewsletterSection').value );
							requestData.push( 'email='+container.querySelector('input[name="email"]').value );
							requestData.push( 'number='+container.getAttribute('data-number') );
							requestData.push( 'lang='+container.querySelector('input[name="lang"]').value );
							requestData.push( 'tag='+container.getAttribute('data-tag') );

							if( container.querySelector('input[name="name"]').value ) {

								requestData.push( 'name='+container.querySelector('input[name="name"]').value );

							}

							requestData = requestData.join('&');

						// -- END -- // 

						var request = new XMLHttpRequest();

						request.onreadystatechange = function() {

							if( request.responseText ) {

								response = JSON.parse(request.responseText);

								if( response.status == 'success' ) {
									
									removeCallBack();

									if( container.classList.contains('newsletter--share__file') || container.classList.contains('newsletter--share__url') ) {

										if( response.url  ) {

											formSubmit = true;

											submitButton.classList.add('turnOff');
											setUrlParameter( response.url );
											changeLayout();

										} else {

											removeCallBack(callbackError, 'error');
											submitButton.classList.remove('disable');

										}										

									} else {

										formSubmit = true;
										changeLayout();	

									}

								} else if( response.status == 'error' ) {

									removeCallBack(callbackError, 'error');
									submitButton.classList.remove('disable');

								}

							}

						}

						// Set up our request
						request.open('POST', document.querySelector('footer').getAttribute('data-endpoint') );

						// Add the required HTTP header for form data POST requests
						request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

						// Finally, send our data.
						request.send(requestData);
						
					} else {

						submitButton.classList.remove('disable');

					}

				}

			});

		}

	},
	finalize() {

	}

}