import $ from 'jquery';

export default {

	init() {

		var container = document.querySelector('.ElasticFullImageContent');

		if( container ) {

			function elasticFullImageContentScroll( container ) {

				var scrollButton = container.querySelector('.elasticFullImageContent__content .button');

				if( scrollButton ) {

					var scrollButtonHref = scrollButton.getAttribute('href');
					var scrollButtonHrefSplit = scrollButtonHref.split('#');

					if( scrollButtonHrefSplit[0] == '' && document.querySelector('#'+scrollButtonHrefSplit[1]) ) {

						scrollButton.addEventListener('click', function( e ) {

							e.preventDefault();

							$('html ,body').animate({'scrollTop': document.querySelector('#'+scrollButtonHrefSplit[1]).offsetTop + document.querySelector('.Header').offsetHeight }, 'smooth');

						});

					}
					
				}

			}

			elasticFullImageContentScroll( container )

		}

	},
	finalize() {

	}

}