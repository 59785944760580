import $ from 'jquery';
import Opinion from '../../Sections/ArticleImageRow/ArticleImageRow';
import NewsletterComponent from '../../Components/NewsletterComponent/NewsletterComponent';
import HeadSection from '../../Sections/HeadSection/HeadSection';
import ContactForm from '../../Sections/ContactForm/ContactForm';
import ElasticSlaiderLogo from '../../Sections/ElasticSlaiderLogo/ElasticSlaiderLogo';


export default {

	init() {

		Opinion.init();
		NewsletterComponent.init();
		HeadSection.init();
		ContactForm.init();
		ElasticSlaiderLogo.init();

		function scrollToNewsletter() {

			var buttons = document.querySelectorAll('.scrollToNewsLetter');

			var newsLetter = document.querySelector('.Newsletter');
			var newsLetterTop = newsLetter.offsetTop;

			buttons.forEach( function( button, index ) {

				button.addEventListener('click',function(e) {

					e.preventDefault();

					$('html ,body').animate({'scrollTop': newsLetterTop }, 'smooth');

				});

			});

		}

		// scrollToNewsletter();

	},
	finalize() {

	}

}