import $ from 'jquery';

export default {

	init() {

		var container = document.querySelector('.HeadSection');

		if( container ) {

			function headSectionScrolltoNextSection(container) {

				function index(el) {

					if (!el) return -1;

					var i = 0;

					do {
						i++;
					} while (el = el.previousElementSibling);

					return i++ ;
				}

				var buttonScroll = container.querySelector('.scrollToNextSection');

				var sections = document.querySelectorAll('section');

				if( sections[index(container)] ) {

					buttonScroll.addEventListener('click', function() {

						$('html ,body').animate({'scrollTop': sections[ index(container) ].offsetTop }, 'smooth');

					});

				}

			}

			headSectionScrolltoNextSection(container);

		}

	},
	finalize() {

	}

}