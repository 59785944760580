import NewsletterComponent from '../../Components/NewsletterComponent/NewsletterComponent';

export default {

	init() {

		NewsletterComponent.init();
		console.log('123');
		
	},
	finalize() {

	}

}